import { render, staticRenderFns } from "./Approvedh.vue?vue&type=template&id=6e46a265&"
import script from "./Approvedh.vue?vue&type=script&lang=js&"
export * from "./Approvedh.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports