<template>
    <div class="container-fluid">
        <div class="row ps-3 m-0 p-0">
            <div class="col-md-3 row p-0">
                <div class="col-md-3 p-0 pt-2">
                    <p class="fw-bold">lokasi :</p>
                </div>
                <div class="col-md-9 mb-0">
                    <select v-model="pages.dept" class="form-select mb-3" style="width: 100%;" required v-on:change="loadData($event)">
                        <option class="" v-for="option in dept" v-bind:value="option.value" :key="option.value">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-9">
                <Toolbar
                :click-edit="showEdit"
                :click-refresh="showRefresh"
                :set-filter="loadDataSearch"
                :status-print="false"
                :data   = "recs"
                :fields = "json_fields"
                type    = "xls"
                name    = "Approve.xls"
            />  
            </div>
        </div>
        <div class="container-fluid p-0">
            <div id="wrapper">
                <div id="content">
                    <div class="w3-hide-small w3-hide-medium" id="boundary">
                        <table id="header" cellpadding="0" cellspacing="5" border="0" class="table table-hover table-strip">
                            <thead>
                                <tr class="">
                                    <th width="50px">No. </th>
                                    <th v-for="fl in filterByShow" :width="fl.width" :key="fl.name" class="h9 text-center">{{fl.name}}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-215) +'px;'">
                        <table class="table table-hover table-strip table-bordered">
                            <tbody>
                            <tr v-for="(dt, index) in recs" :key="dt.ta_so" class="w3-hover-pale-blue w3-small cursor" :class="index == selectedRow ?'w3-pale-blue':''">
                                <td class="w3-hide-large" v-on:click="setKlikRow(index, dt)">
                                    <div class="w3-col s4 m2 w3-theme-l5">
                                        <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>Nomer SO
                                    </div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.ta_so}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Customer</div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.tc_name}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Nominal</div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.ta_amt | toRp}}
                                    </div>
                                    <div class="w3-col s4 m2 w3-theme-l5">Tempo</div>
                                    <div class="w3-col s8 m10 w3-theme-l4">
                                        : {{dt.ta_top}}
                                    </div>
                                </td>
                                <td width="50px" class="text-center">
                                    <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>&nbsp;{{dt.idx}}
                                    <span class="w3-hide-large" v-html="setStatus(dt.ta_approved)"></span>
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ta_so')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.ta_so}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ta_IdCust')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.tc_name}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ta_date')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.ta_date}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ta_amt')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.ta_amt | toRp}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ta_top')" class="w3-hide-small w3-hide-medium text-center">
                                    {{dt.ta_top}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','changed_by')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.changed_by}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ta_update')" class="w3-hide-small w3-hide-medium text-center">
                                    {{dt.ta_update}}
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:' + findObjFind('width','ta_approved')" class="w3-hide-small w3-hide-medium">
                                    <span v-html="setStatus(dt.ta_approved)"></span>
                                </td>
                                <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','ta_reason')" class="w3-hide-small w3-hide-medium ">
                                    {{dt.ta_reason}}    
                                </td>
                            </tr>
                        </tbody>                                            
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- pagging -->
        <nav class="Page navigation h9 bg-light bg-gradient shadow">
            <div class="d-flex justify-content-center">
                <ul class="pagination text-center">
                    <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="first()" href="#">First</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="prev()" href="#">«</a></li>
                    <li class="page-item page-item active"><a class="page-link px-0 px-md-2" href="#">Page {{pages.halaman}} sd {{pages.ttlhalaman}}</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="next()" href="#">»</a></li>
                    <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="last()" href="#">Last</a></li>
                    <li class="page-item page-item active"><a class="page-link  page-item active" href="#">Rec {{pages.ttlrec}}</a></li>
                    </ul>
            </div>
        </nav>
        
    </div>
</template>
<script>
/* eslint-disable */
import Config from '@/conf/Config.js';
import axios from "axios";
import "vue-search-select/dist/VueSearchSelect.css";
import Toolbar from "@/components/ToolBarse.vue";

import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5"
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css"
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";


export default {

    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Approved',
    components: {
        Toolbar
    },
    data() {
        return {
            screenHeight : 0,
            selectedRow : -1,
            dept: [
                { text: 'SURABAYA', value: '1' },
                { text: 'SEMARANG', value: '2' },
                { text: 'Semua', value: '3' },
            ],
            rec: {},
            recs: [],
            pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, limit:Config.perpage, search:'',dept : '3',},
            filters : {
            	operator : 'AND',
                fields :[
                        {fieldFind : '', key : 'ta_so', type: 'text', name : 'Nomer SO', filter : true, width : '120px', grid : true},
                        {fieldFind : '', key : 'ta_IdCust', type: 'text', name : 'Customer', filter : true, width : '150px', grid : true},
                        {fieldFind : '', key : 'ta_date', type: 'text', name : 'Tanggal Pengajuan', filter : true, width : '150px', grid : true},
                        {fieldFind : '', key : 'ta_amt', type: 'text', name : 'Nominal', filter : true, width : '100px', grid : true},
                        {fieldFind : '', key : 'ta_top', type: 'text', name : 'Tempo', filter : true, width : '60px', grid : true},
                        {fieldFind : '', key : 'changed_by', type: 'text', name : 'User', filter : true, width : '100px', grid : true},
                        {fieldFind : '', key : 'ta_update', type: 'text', name : 'Approved', filter : true, width : '150px', grid : true},
                        {fieldFind : '', key : 'ta_approved', type: 'text', name : 'Status', filter : true, width : '35px', grid : true, valueFind: [{key:'1',label:'Approved'},{key:'0',label:'Non'},{key:'2',label:'Void'}]},
                        {fieldFind : '', key : 'ta_reason', type: 'text', name : 'Keterangan', filter : true, width : '150px', grid : true},
                ],
                find : []   
            },
            /**Untuk transfer excel*/
            json_fields : {
                'SO' : 'ta_so',
                'NAMA CUSTOMER' : 'tc_name',
                'TANGGAL PENGAJUAN' : 'ta_date',
                'Unpaid Invoice' : 'ta_outstandingSO',
                'NOMINAL' : 'ta_amt',
                'KREDIT LIMIT' : 'ta_creditLimit',
                'TEMPO' : 'ta_top',
                'PEMBUAT SO' : 'changed_by',
                'STATUS' : 'ta_approved',
                'ALASAN PENGAJUAN' : 'ta_reason'
            },
        }
    },
    methods : {
        changeDept(event){
            let self = this;
            self.LoadData();
        },

        findObjFind(key, val){
            return this.findObj(this.filters.fields,'key',val)[key];
        },
        findField(val){
            return this.findObj(this.fieldRules, 'field',val);
        },
        findObj(obj, key, value) {
            let ketemu = 0;
            for (var pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        first(){
            let self = this;
            self.pages.halaman = 1;
            self.loadData();
        },
        prev(){
            let self = this;
            self.pages.halaman = self.pages.halaman - 1;
            self.loadData();
        },
        next(){
            let self = this;
            self.pages.halaman = self.pages.halaman + 1;
            self.loadData();
        },
        last(){
            let self = this;
            self.pages.halaman = self.pages.ttlhalaman;
            self.loadData();
        },
        setKlikRow(index, dt){
            let self = this;
            self.selectedRow = index;
            self.rec = Object.assign({}, dt);
        },
        loadData(){
            this.screenHeight = window.innerHeight;
            let self = this;
            self.selectedRow = -1;
            self.dept;
            self.rec = {};
            let params = {
                pfunction:'showh',
                limit: self.pages.limit,
                page: self.pages.halaman,
                search: self.pages.search,
                dept : self.pages.dept
            }
            axios.post("master/ApiApproved.php",params).then(function (response) {
                let idx = (self.pages.limit*self.pages.halaman)-self.pages.limit+1;
                self.pages.ttlrec = parseFloat(response.data.ttlrec);
                self.pages.ttlhalaman = Math.ceil(parseFloat(response.data.ttlrec)/self.pages.limit);
                self.pages.dept = self.pages.dept;
                response.data.rec.map(function(val){
                    val['idx'] = idx;
                    idx++;
                });
                self.recs = response.data.rec;
            });
        },
        showRefresh(){
            this.pages.search = "";
            this.loadData();
        },
        loadDataSearch(filter){
            this.pages.search = filter;
            this.loadData();
        },
        showEdit(){
            let self = this;
            if (self.selectedRow < 0) {
                this.$toast.error('Pilih Data');
                return false;
            }else{
                this.stsAdd = false;
                document.getElementById('modal-form').style.display='block';
                setTimeout(function(){
                    document.getElementById('ta_so').focus();
                },500);
            }
        },
        saveData(event){
            var self = this;
            if (self.stsAdd == true) {
                self.rec.pfunction = "add";
            }else{
                self.rec.pfunction = "edit";
            }
            axios.post("master/ApiApproved.php",self.rec).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    if (self.stsAdd) {
                        self.recs.splice(0, 0, self.rec);
                    }else{
                        self.recs[self.selectedRow] = self.rec;
                    }
                    document.getElementById('modal-form').style.display='none';
                    self.selectedRow = -1;
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        setStatus(dt) {
            if (dt === "1") {
                return "<div class='btn btn-success btn-sm' ><span class='fa fa-check'></span></div>";
            } else if (dt === "0"){
                return "<div class='btn btn-danger btn-sm'><span class='fa fa-close'></span><div>";
            } else {
                return "<div class='btn btn-warning btn-sm'><span class='fa fa-close'></span><div>";
            }
        },
        setInit() {
            let self = this;
            axios.post("master/ApiApproved.php",{
                pfunction : 'setapproved'
            }).then(function (response) {
                self.customers = response.data.rec;
            });
        }
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("id-ID",{ style: 'currency', currency: 'IDR' }).format(value)}`;
        },
        pNumber(num) {
            var x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        }
    },
    watch: {
        // 'moveCenter': function(){
        //     console.log("OK");
        // }
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;                
            });
        }
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        // this.setInit();
        this.loadData();
    }
};
</script>